body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  hr {
    margin: 20px 0;
	padding: 0;
	height: 3px;
    border-radius: 3px;
	border: none;
	background: linear-gradient(45deg, #1876d2, #292e3c);
  }
/* Header styles start */
.header { 
    background: #292e3c;
    border-bottom: 1;
    border-color: divider; 
    border-radius: 20px;
    margin-top:10px;
    position:sticky;
    top: 10px;
    left: 0px;
    z-index:10;
    padding: 18px;
}
.css-bqh7lh-MuiToolbar-root {
    width: 100%;
    overflow: scroll;
}
.header_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_logo_container {
    display: flex;
    align-items: center;
}
.mainLogo {
    border-radius: 30px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.mainName {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header_link {
    color:white;
    text-decoration:none;
    font-weight:lighter;
    cursor: pointer;
    text-transform: uppercase;
}
.ml-10 {
    margin-left:10px;
}
.open_burger {
    display: none;
}

.nav_block {
    display: flex;
}
.nav_block_burder {
    display: none;
}
@media (min-width: 900px) {
    .open_burger {
        display: none;
    }
    .nav_block {
        display: flex;
    }
    .nav_block_burder {
        display: none;
    } 
}

@media (max-width: 900px) {
    .nav_block {
        display: none;
    }
    .open_burger {
        display: flex;
    }
    .nav_block_burder {
        display: flex; 
        justify-content: center; 
        flex-direction: column;
        align-items: center;
    }
}
/* Header styles end */
.mainPostBlock > div {
    background-image: linear-gradient(to top, rgb(0 0 0 / 45%), rgb(50 50 50 / 30%)), url(/public/code.jpg);
}
label {
    cursor: pointer;
}



.MainBoxContainer {}
.MainBoxContainerText {}
.MainBoxContainerSubText {}

.choocePhoto {
    display: block;
    width: 100%;
    background-color: #292e3c;
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.MuiButton-containedPrimary {
    padding: 10px !important;
    font-weight: bold !important;
}

@media (max-width:800px) {
    .MainBoxContainerText {
        font-size: 25px !important;
    }
    .MainBoxContainerSubText {
        font-size: 15px !important;
    }
}

@media (max-width: 700px) {
    .css-c49kz {
        flex-wrap: wrap;
    }
}
 
